var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"extension-manager"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[(
  Boolean(_vm.extensionData.hexflowerAsExtension) &&
  (!_vm.extensionList.hexflowerFirstVisible ||
    _vm.extensionList.hexflowerFirstVisible <=
      _vm.roomInfo.currentCardIndex) &&
  (!_vm.extensionList.hexflowerLastVisible ||
    _vm.extensionList.hexflowerLastVisible > _vm.roomInfo.currentCardIndex) &&
  ((!_vm.extensionList.hexflowerAsExtensionLocation &&
    _vm.extensionLocation == 'lower') ||
    _vm.extensionList.hexflowerAsExtensionLocation == _vm.extensionLocation)
)?_c('app-game',{staticClass:"extension",attrs:{"gameAsExtension":Boolean(_vm.extensionData.hexflowerAsExtension),"gSheetID":_vm.extensionData.hexflowerAsExtension.substring(
            _vm.extensionData.hexflowerAsExtension.indexOf('/d/') + 3,
            _vm.extensionData.hexflowerAsExtension.indexOf('/edit')
          ),"gameType":'Hexflower',"roomID":_vm.$route.params.roomID + '-hexflower-extensions'}}):_vm._e(),(
  Boolean(_vm.extensionData.generatorAsExtension) &&
  (!_vm.extensionList.generatorFirstVisible ||
    _vm.extensionList.generatorFirstVisible <=
    _vm.roomInfo.currentCardIndex) &&
  (!_vm.extensionList.generatorLastVisible ||
    _vm.extensionList.generatorLastVisible > _vm.roomInfo.currentCardIndex) &&
  ((!_vm.extensionList.generatorAsExtensionLocation &&
    _vm.extensionLocation == 'lower') ||
    _vm.extensionList.generatorAsExtensionLocation == _vm.extensionLocation)
)?_c('app-game',{staticClass:"extension",attrs:{"gameAsExtension":Boolean(_vm.extensionData.generatorAsExtension),"gSheetID":_vm.extensionData.generatorAsExtension.substring(
            _vm.extensionData.generatorAsExtension.indexOf('/d/') + 3,
            _vm.extensionData.generatorAsExtension.indexOf('/edit')
          ),"gameType":'Generator',"roomID":_vm.$route.params.roomID + '-generator-extensions'}}):_vm._e(),(
          _vm.extensionData.staticBoxContent &&
          (!_vm.extensionList.staticBoxContentFirstVisible ||
            _vm.extensionList.staticBoxContentFirstVisible <=
            _vm.roomInfo.currentCardIndex) &&
          (!_vm.extensionList.staticBoxContentLastVisible ||
            _vm.extensionList.staticBoxContentLastVisible >
            _vm.roomInfo.currentCardIndex) &&
          ((!_vm.extensionList.staticBoxLocation &&
            _vm.extensionLocation == 'lower') ||
            _vm.extensionList.staticBoxLocation == _vm.extensionLocation)
        )?_c('app-staticBox',{staticClass:"extension",attrs:{"staticBoxContent":_vm.extensionData.staticBoxContent}}):_vm._e(),(
            _vm.extensionList['interrupts'] &&
            (!_vm.extensionList.interruptsFirstVisible ||
              _vm.extensionList.interruptsFirstVisible <=
                _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.interruptsLastVisible ||
              _vm.extensionList.interruptsLastVisible >
                _vm.roomInfo.currentCardIndex) &&
            ((!_vm.extensionList.interruptsLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.interruptsLocation == _vm.extensionLocation)
          )?_c('app-interrupts',{staticClass:"extension",attrs:{"extensionList":this.extensionList,"interruptTopText":_vm.extensionList.interruptTopText,"currentInterrupt":_vm.extensionData.currentInterrupt,"menuLocation":false,"roomInfo":_vm.roomInfo,"customOptions":_vm.customOptions,"userRole":_vm.userRole},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            _vm.extensionList['playerTurnOrder'] &&
            (!_vm.extensionList.playerTurnOrderFirstVisible ||
              _vm.extensionList.playerTurnOrderFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.playerTurnOrderLastVisible ||
              _vm.extensionList.playerTurnOrderLastVisible >
              _vm.roomInfo.currentCardIndex) &&
            ((!_vm.extensionList.playerTurnOrderLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.playerTurnOrderLocation == _vm.extensionLocation)
          )?_c('app-playerTurnOrder',{staticClass:"extension",attrs:{"playerTurnOrder":JSON.parse(_vm.extensionData.playerTurnOrder),"playerTurnOrderHeader":_vm.extensionData.playerTurnOrderHeader,"playerTurnOrderButtonLabel":_vm.extensionData.playerTurnOrderButtonLabel,"playerTurnOrderFirstVisible":parseInt(_vm.extensionData.playerTurnOrderFirstVisible),"currentCardIndex":_vm.roomInfo.currentCardIndex},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            _vm.extensionList['imageGallery'] &&
            (!_vm.extensionList.imageGalleryFirstVisible ||
              _vm.extensionList.imageGalleryFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.imageGalleryLastVisible ||
              _vm.extensionList.imageGalleryLastVisible >
              _vm.roomInfo.currentCardIndex) &&
            ((!_vm.extensionList.imageGalleryLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.imageGalleryLocation == _vm.extensionLocation)
          )?_c('app-imageGallery',{staticClass:"extension",attrs:{"imageGallery":_vm.extensionData.imageGallery
            ? _vm.extensionData.imageGallery.split(',')
            : null,"imageCaptions":_vm.extensionData.imageGalleryCaptions
    ? _vm.extensionData.imageGalleryCaptions.split(',')
    : null,"imageAltText":_vm.extensionData.imageGalleryAltText ? _vm.extensionData.imageGalleryAltText.split(',') : null,"imageGalleryTitle":_vm.extensionData.imageGalleryTitle,"selectedImage":_vm.extensionData.selectedImage},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            _vm.extensionList['plusMinus'] &&
            (!_vm.extensionList.plusMinusFirstVisible ||
              _vm.extensionList.plusMinusFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.plusMinusLastVisible ||
              _vm.extensionList.plusMinusLastVisible > _vm.roomInfo.currentCardIndex) &&
            ((!_vm.extensionList.plusMinusLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.plusMinusLocation == _vm.extensionLocation)
          )?_c('app-plusMinus',{staticClass:"extension",attrs:{"plusMinus":JSON.parse(_vm.extensionData.plusMinus),"plusMinusTitle":_vm.extensionData.plusMinusTitle},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            (_vm.extensionList['editableList'] ||
              _vm.extensionList.editableListFirstVisible ||
              _vm.extensionList.editableListTitle) &&
            (!_vm.extensionList.editableListFirstVisible ||
              _vm.extensionList.editableListFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.editableListLastVisible ||
              _vm.extensionList.editableListLastVisible >
              _vm.roomInfo.currentCardIndex) &&
            ((!_vm.extensionList.editableListLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.editableListLocation == _vm.extensionLocation)
          )?_c('app-editableList',{staticClass:"extension",attrs:{"editableList":_vm.extensionData.editableList.split(','),"editableListTitle":_vm.extensionData.editableListTitle,"editableListUpper":_vm.extensionData.editableListUpper},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            _vm.extensionList['multiEditableLists'] &&
            (!_vm.extensionList.multiEditableListsFirstVisible ||
              _vm.extensionList.multiEditableListsFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.multiEditableListsLastVisible ||
              _vm.extensionList.multiEditableListsLastVisible >
              _vm.roomInfo.currentCardIndex) &&
            ((!_vm.extensionList.multiEditableListsLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.multiEditableListsLocation == _vm.extensionLocation)
          )?_c('app-multiEditableLists',{staticClass:"extension",attrs:{"multiEditableLists":JSON.parse(_vm.extensionData.multiEditableLists)},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            _vm.extensionList['diceRoller'] &&
            (!_vm.extensionList.diceRollerFirstVisible ||
              _vm.extensionList.diceRollerFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.diceRollerLastVisible ||
              _vm.extensionList.diceRollerLastVisible >
              _vm.roomInfo.currentCardIndex) &&
            ((!_vm.extensionList.diceRollerLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.diceRollerLocation == _vm.extensionLocation)
          )?_c('app-diceRoller',{staticClass:"extension",attrs:{"diceResults":_vm.extensionData.diceRoller.split(','),"diceRollerTitle":_vm.extensionData.diceRollerTitle},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            _vm.extensionList['coinflip'] &&
            ((!_vm.extensionList.coinflipLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.coinflipLocation == _vm.extensionLocation) &&
            (!_vm.extensionList.coinflipFirstVisible ||
              _vm.extensionList.coinflipFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.coinflipLastVisible ||
              _vm.extensionList.coinflipLastVisible > _vm.roomInfo.currentCardIndex)
          )?_c('app-coinflip',{staticClass:"extension",attrs:{"flipResult":_vm.extensionData.flipResult,"coinflipValues":_vm.extensionData.coinflip,"coinflipTitle":_vm.extensionData.coinflipTitle,"coinflipButtonLabel":_vm.extensionData.coinflipButtonLabel},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            _vm.extensionList['standardDeck'] &&
            (!_vm.extensionList.standardDeckFirstVisible ||
              _vm.extensionList.standardDeckFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.standardDeckLastVisible ||
              _vm.extensionList.standardDeckLastVisible >
              _vm.roomInfo.currentCardIndex) &&
            ((!_vm.extensionList.standardDeckLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.standardDeckLocation == _vm.extensionLocation)
          )?_c('app-standardDeck',{staticClass:"extension",attrs:{"standardDeck":JSON.parse(_vm.extensionData.standardDeck),"standardDeckTitle":_vm.extensionData.standardDeckTitle},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            (_vm.extensionList['journalEntries'] ||
              _vm.extensionList.journalUpperText) &&
            ((!_vm.extensionList.journalEntiresLocation &&
              _vm.extensionLocation == 'lower') ||
              _vm.extensionList.journalEntriesLocation == _vm.extensionLocation) &&
            (!_vm.extensionList.journalFirstVisible ||
              _vm.extensionList.journalFirstVisible <= _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.journalLastVisible ||
              _vm.extensionList.journalLastVisible > _vm.roomInfo.currentCardIndex)
          )?_c('app-journal',{staticClass:"extension",attrs:{"journalEntries":JSON.parse(_vm.extensionData.journalEntries),"journalUpperText":_vm.extensionData.journalUpperText,"journalOrder":_vm.extensionData.journalOrder},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
            _vm.extensionList['currentPlayerHeader'] &&
            _vm.extensionList['playerTurnOrder'] &&
            _vm.extensionLocation == 'upper' &&
            (!_vm.extensionList.currentPlayerHeaderFirstVisible ||
              _vm.extensionList.currentPlayerHeaderFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
            (!_vm.extensionList.currentPlayerHeaderLastVisible ||
              _vm.extensionList.currentPlayerHeaderLastVisible >
              _vm.roomInfo.currentCardIndex)
          )?_c('app-currentPlayerHeader',{staticClass:"extension",attrs:{"playerTurnOrder":JSON.parse(_vm.extensionData.playerTurnOrder),"currentPlayerHeader":_vm.extensionList.currentPlayerHeader,"playerTurnOrderFirstVisible":parseInt(_vm.extensionData.playerTurnOrderFirstVisible),"currentCardIndex":_vm.roomInfo.currentCardIndex},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e(),(
  (_vm.extensionList['hardcodedWebsiteURL'] ||
    _vm.extensionList['embedWebsiteMessage']) &&
  (((!_vm.extensionList.hardcodedWebsiteURLFirstVisible ||
    _vm.extensionList.hardcodedWebsiteURLFirstVisible <=
    _vm.roomInfo.currentCardIndex) &&
    (!_vm.extensionList.hardcodedWebsiteURLLastVisible ||
      _vm.extensionList.hardcodedWebsiteURLLastVisible >
      _vm.roomInfo.currentCardIndex)) ||
    ((!_vm.extensionList.embedWebsiteMessageFirstVisible ||
      _vm.extensionList.embedWebsiteMessageFirstVisible <=
      _vm.roomInfo.currentCardIndex) &&
      (!_vm.extensionList.embedWebsiteMessageLastVisible ||
        _vm.extensionList.embedWebsiteMessageLastVisible >
        _vm.roomInfo.currentCardIndex))) &&
  ((!_vm.extensionList.embededWebsiteLocation &&
    _vm.extensionLocation == 'lower') ||
    _vm.extensionList.embededWebsiteLocation == _vm.extensionLocation)
          )?_c('app-embedWebsite',{staticClass:"extension",attrs:{"hardcodedWebsiteURL":_vm.extensionData.hardcodedWebsiteURL,"selectedWebsiteURL":_vm.extensionData.selectedWebsiteURL,"embedWebsiteMessage":_vm.extensionData.embedWebsiteMessage},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }